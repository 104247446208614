<template>
    <div class="ecg-details">
        <div class="banner" v-if="detail.emergency" :style="{backgroundImage:'url('+require('@/assets/img/ecg-detail-back-banner'+detail.emergency+'.png')+')'}">
            <img src="@/assets/img/ecg-putong-icon.png" v-if="detail.emergency == 1" class="img-right" alt="">
            <img src="@/assets/img/ecg-yujin-icon.png" v-if="detail.emergency == 2" class="img-right" alt="">
            <img src="@/assets/img/ecg-weiji-icon.png" v-if="detail.emergency == 3" class="img-right" alt="">
            <img src="@/assets/img/ecg-wufa-icon.png" v-if="detail.emergency == 4" class="img-right" alt="">
        </div>
        <div class="detail-info" :class="'info'+detail.emergency" :style="{backgroundImage:'url('+require('@/assets/img/ecg-detail-back-'+detail.emergency+'.png')+')'}">
            <p class="title">签发机构：{{detail.agency}}</p>
            <div class="text-container">
                <p class="h">
                    {{detail.emergency == 1?'建议继续观察':detail.emergency == 2?'建议就近就医':detail.emergency == 3?'建议紧急送医':'建议重新采集'}}
                </p>
                <p class="txt">{{detail.diagnosis}}</p>
            </div>
            <div class="ecg-time">
                <i class="line"></i>
                <p class="time-txt">上传：{{detail.requestTime}}</p>
                <p class="time-txt">审核：{{detail.ecgReportTime}}</p>
            </div>
        </div>
        <div class="ecg-img">
            <p class="title">心电图报告</p>
            <img :src="detail.finalEcgReport" @click="seeImg" alt="">
        </div>
    </div>
</template>

<script>
import { ecgReportGet } from "@/api/EcgReport";
import { ImagePreview } from 'vant';

export default {
    data () {
        return {
            kangnaixinId:this.$route.query.id,
            detail:{}
        }
    },
    created () {
        document.title = this.$t("EcgReport.name");
        this.getDetails()
    },
    methods: {
        getDetails(){
            const toast = this.$Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            ecgReportGet({
                kangnaixinId:this.kangnaixinId
            }).then(res =>{
                toast.clear()
                this.detail = res.data.data;
            })
        },
        seeImg(){
            ImagePreview([this.detail.finalEcgReport]);
        }
    }
}
</script>

<style lang="less" scoped>
.ecg-details{
    height: 100%;
    .banner{
        height: 6rem;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 5.6rem;
            height: 5.4rem;
        }
    }
    .detail-info{
        margin: 0 0.6rem;
        padding: 0.2rem 0.8rem;
        border-radius: 0.16rem;
        background-size: 100% 100%;
        .title{
            height: 1.4rem;
            line-height: 1.4rem;
            text-align: right;
            font-size: 0.52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
        .text-container{
            padding: 0.6rem;
            background: #fff;
            border-radius: 0.16rem;
            .h{
                font-size: 0.72rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 1rem;
            }
            .txt{
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6C6C6E;
                line-height: 0.86rem;
            }
        }
        .ecg-time{
            padding-left: 0.8rem;
            margin: 0.3rem 0;
            position: relative;
            .line{
                height: 50%;
                width: 0.06rem;
                background:#D8D8D8;
                position: absolute;
                left: 0.25rem;
                top: 50%;
                transform: translate(0, -50%);
            }
            .time-txt{
                font-size: 0.52rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #989898;
                position: relative;
                &:last-child{
                    margin-top: 0.2rem;
                }
                &::after{
                    position: absolute;
                    content: ' ';
                    left: -0.6rem;
                    top: 50%;
                    width: 0.2rem;
                    height: .2rem;
                    background: #D8D8D8;
                    transform: translate(0, -50%);
                    border-radius: 50%;
                }
            }
        }
    }
    .info1{
        color: #2CBAA6;
    }
    .info2{
        color: #FFA14C;
    }
    .info3{
        color: #FF5734;
    }
    .info4{
        color: #A7A7A7;
    }
    .ecg-img{
        margin: 0.4rem 0.6rem;
        .title{
            font-size: 0.72rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 1rem;
        }
        img{
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 12rem;
            display: block;
            margin: 0.32rem auto;
        }
    }
}
</style>